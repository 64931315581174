
export function formatDate (date) {
    if (!date) return null

    const [year, month, day] = date.split('-')
    return `${day}/${month}/${year}`
}


export function cloneObject(obj) {
    var clone = {};
    for(var i in obj) {
        if(typeof(obj[i])=="object" && obj[i] != null)
            clone[i] = cloneObject(obj[i]);
        else
            clone[i] = obj[i];
    }
    return clone;
}


export function diffObject (obj1, obj2) {
    let pvt = {}
    Object.keys(obj1).forEach(key => {
        if (obj1[key] !== obj2[key]) {
            pvt[key] = obj2[key]
        }
    })
    return pvt
}


export function print(obj) {
    return console.log(JSON.stringify(obj, null, 2))
}