export const Country = ["Greece", "Albania", "Bulgaria", "North Macedonia", "Bosnia", "Croatia", "Serbia"]

export const UrbanIncidentChoices = ["Yes", "No", "Suburban", "Unknown"]

export const PrivOrPublicLand = ["Private", "Public", "Unknown"]

export const HuntingReserve = ["Private", "Public", "None", "Unknown"]

export const ProtectionStatus = [
  "National Park",
  "Nature park",
  "Nature reserve",
  "Controlled hunting area",
  "Wildlife sanctuary",
  "SPA",
  "SCI",
  "SPA/SCI",
  "Ramsar",
  "IBA",
  "Biosphere reserve",
  "Other",
  "None",
  "Unknown",
]

export const PrimaryHabitat = [
  "111-Urban fabric-Continuous urban fabric",
  "112-Urban fabric-Discontinuous urban fabric",
  "121-Industrial, commercial and transport units-Industrial or commercial units",
  "122-Industrial, commercial and transport units-Road and rail networks and associated land",
  "123-Industrial, commercial and transport units-Port areas",
  "124-Industrial, commercial and transport units-Airports",
  "131-Mine, dump and construction sites-Mineral extraction sites",
  "132-Mine, dump and construction sites-Dump sites",
  "133-Mine, dump and construction sites-Construction sites",
  "141-Artificial, non-agricultural vegetated areas-Green urban areas",
  "142-Artificial, non-agricultural vegetated areas-Sport and leisure facilities",
  "211-Arable land-Non-irrigated arable land",
  "212-Arable land-Permanently irrigated land",
  "213-Arable land-Rice fields",
  "221-Permanent crops-Vineyards",
  "222-Permanent crops-Fruit trees and berry plantations",
  "223-Permanent crops-Olive groves",
  "231-Pastures-Pastures",
  "241-Heterogeneous agricultural areas-Annual crops associated with permanent crops",
  "242-Heterogeneous agricultural areas-Complex cultivation patterns",
  "243-Heterogeneous agricultural areas-Land principally occupied by agriculture, with significant areas of natural vegetation",
  "311-Forests-Broad-leaved forest",
  "312-Forests-Coniferous forest",
  "313-Forests-Mixed forest",
  "321-Scrub and/or herbaceous vegetation associations-Natural grasslands",
  "322-Scrub and/or herbaceous vegetation associations-Moors and heathland",
  "323-Scrub and/or herbaceous vegetation associations-Sclerophyllous vegetation",
  "324-Scrub and/or herbaceous vegetation associations-Transitional woodland-shrub",
  "331-Open spaces with little or no vegetation-Beaches, dunes, sands",
  "332-Open spaces with little or no vegetation-Bare rocks",
  "333-Open spaces with little or no vegetation-Sparsely vegetated areas",
  "334-Open spaces with little or no vegetation-Burnt areas",
  "411-Inland wetlands-Inland marshes",
  "421-Maritime wetlands-Salt marshes",
  "422-Maritime wetlands-Salines",
  "511-Inland waters-Water courses",
  "512-Inland waters-Water bodies",
  "521-Marine waters-Coastal lagoons",
  "522-Marine waters-Estuaries",
  "523-Marine waters-Sea and ocean",
  "Other",
  "Unknown",
]

export const WeatherPrevDay = [
  "Sunny, dry",
  "Sunny, dry, high temperatures",
  "Cloudy, no rain",
  "Light showers",
  "Rain",
  "Heavy rain/ Strong thunderstorms",
  "Snow",
  "Unknown",
]

export const AnimalSpecies = [
    "Bird - Accipiter brevipes",
    "Bird - Accipiter gentilis",
    "Bird - Accipiter nisus",
    "Bird - Aegolius funereus",
    "Bird - Aegypius monachus",
    "Bird - Aquila chrysaetos",
    "Bird - Aquila clanga",
    "Bird - Aquila heliaca",
    "Bird - Aquila nipalensis",
    "Bird - Aquila pomarina",
    "Bird - Asio flammeus",
    "Bird - Asio otus",
    "Bird - Athene noctua",
    "Bird - Bubo bubo",
    "Bird - Buteo buteo",
    "Bird - Buteo lagopus",
    "Bird - Buteo rufinus",
    "Bird - Circaetus gallicus",
    "Bird - Circus aeruginosus",
    "Bird - Circus cyaneus",
    "Bird - Circus macrourus",
    "Bird - Circus pygargus",
    "Bird - Corvidae spp",
    "Bird - Corvus corax",
    "Bird - Corvus corone",
    "Bird - Elanus caeruleus",
    "Bird - Falco biarmicus",
    "Bird - Falco cherrug",
    "Bird - Falco columbarius",
    "Bird - Falco eleonorae",
    "Bird - Falco naumanni",
    "Bird - Falco pelegrinoides",
    "Bird - Falco peregrinus",
    "Bird - Falco subbuteo",
    "Bird - Falco tinnunculus",
    "Bird - Falco vespertinus",
    "Bird - Glaucidium passerinum",
    "Bird - Gypaetus barbatus",
    "Bird - Gyps fulvus",
    "Bird - Haliaeetus albicilla",
    "Bird - Hieraaetus fasciatus",
    "Bird - Hieraaetus pennatus",
    "Bird - Larus spp.",
    "Bird - Milvus migrans",
    "Bird - Milvus milvus",
    "Bird - Neophron percnopterus",
    "Bird - Other bird",
    "Bird - Otus scops",
    "Bird - Pandion haliaetus",
    "Bird - Passerine",
    "Bird - Pelecanus crispus",
    "Bird - Pelecanus onocrotalous",
    "Bird - Pernis apivorus",
    "Bird - Pica pica",
    "Bird - Raptor spp",
    "Bird - Strix aluco",
    "Bird - Tyto alba",
    "Cat - Felis catus",
    "Dog - Hunting dog",
    "Dog - Pet dog",
    "Dog - Shepherd dog",
    "Dog - Stray dog",
    "Dog - Truffle dog",
    "Dog - Unidentified dog",
    "Farm animal - Chicken",
    "Farm animal - Cow",
    "Farm animal - Donkey",
    "Farm animal - Goat",
    "Farm animal - Horse",
    "Farm animal - Other",
    "Farm animal - Pig",
    "Farm animal - Sheep",
    "Farm animal - Turkey",
    "Impossible to identify",
    "Insect - Bee",
    "Insect - Other insect",
    "Insect - Wasp",
    "Reptile - Other reptile",
    "Reptile - Testudo graeca",
    "Reptile - Testudo hermanni",
    "Reptile - Testudo marginata",
    "Wild mammal - Canis aureus",
    "Wild mammal - Canis lupus",
    "Wild mammal - Erinaceus europaeus",
    "Wild mammal - Felis silvestris",
    "Wild mammal - Large mammal spp",
    "Wild mammal - Martes foina",
    "Wild mammal - Martes martes",
    "Wild mammal - Meles meles",
    "Wild mammal - Mustela nivalis",
    "Wild mammal - Other mammal",
    "Wild mammal - Small mammal spp",
    "Wild mammal - Sus scrofa",
    "Wild mammal - Ursus arctos",
    "Wild mammal - Vulpes vulpes",
    "Other"
  ]

export const AnimalState = [
    "Vomiting",
    "Convulsions",
    "Vomiting and convulsions",
    "Saliva",
    "Saliva and convulsions",
    "Head drooping / Unable to stand",
    "Lethargic",
    "Bleeding from mouth/gums",
    "Bleeding from anus",
    "Internal hematoma",
    "No external symptoms",
    "Other",
    "Unknown",
  ]

export const FirstAidType = [
    "Apomorphine",
    "Atropine",
    "Activated charcoal",
    "Apomorphine & Atropine",
    "Apomorphine, Atropine & Activated charcoal",
    "Rodenticide antidote",
    "Other",
    "Died before it was possible to provide first aid",
    "None",
    "Unknown",
  ]

export const FirstAidProvider = [
    "Vet",
    "Warden",
    "NGO staff",
    "Animal Owner",
    "Other",
    "Unknown",
    "Not applicable",
  ]

export const FinalStatusValues = [
    "Alive",
    "Dead",
    "Dead despite first aid",
    "Unknown"
  ]

export const StdChoices = ["Yes", "No", "Unknown"]

export const AntipoisonDogID = [
    "Central Greece",
    "Eastern Greece",
    "Thrace Greece",
    "Crete west",
    "Crete east",
    "Crete central",
    "Rhodes",
    "Rhodopes Bulgaria",
    "Unknown",
  ]

export const TimeNotified = [
    "Before incident management",
    "After incident management",
    "Authority was informant",
    "Unknown",
  ]

export const AutopsyCarriedOut = [
    "Only autopsy",
    "Only toxicological analysis",
    "Both",
    "None",
    "Unknown",
    "Not applicable",
  ]

export const Diagnose = [
    "Positive to poison",
    "Negative to poison",
    "Positive to poison but dosage below mortal concentration",
    "Negative to poison but not all toxins could be tested",
    "Inconclusive results",
    "Still running analyses",
    "Results not available for general public/ results under gagging order",
    "Other",
    "Unknown",
  ]

export const NumberOfToxin = ["Unknown", "0", "1", "2", "3"]

export const StateCarcass = [
    "Fresh",
    "Bloat",
    "Active decay",
    "Advanced decay",
    "Dry/remains",
    "Unknown/Not available",
  ]

export const StdChoicesDAI = ["Yes", "No", "Found in multiple days", "Unknown"]

export const AnimalDisposalMethods = [
    "Buried on-site",
    "Burnt on-site",
    "Left in the field",
    "Other",
    "Unknown"]

export const DisposedBy = [
    "Hunter",
    "Stakeholder involved",
    "NGO",
    "Authorities",
    "Other",
    "Unknown"]

export const EvidenceItem = [
        "Plastic gloves",
        "Pesticide bottle / pesticide container",
        "Bait container",
        "Plastic bag",
        "Cigarette butt",
        "Other",
      ]

export const SuspectedReason = [
        "Damages in crops/orchards/groves",
        "Livestock losses",
        "Damages in beehives",
        "Losses In Game",
        "Losses by pigeon fanciers",
        "Problems with stray dogs",
        "Problems with hunting dogs",
        "Problems with sheepdogs",
        "Problems with hunting dogs' training",
        "Land user conflicts between stakeholder groups",
        "Local disputes",
        "Other",
        "Unknown",
      ]

export const Authority = ["Police", "Forestry department", "Both", "Other", "Unknown"]

export const StdChoicesExt = ["Yes", "No", "Unknown", "Not applicable"]

export const CaseSolvedChoices = [
"Yes",
"No",
"Case still open",
"Unknown",
"Not applicable",
]

export const OtherSuspectedCause = [
    "Electrocution",
    "Collision",
    "Shot",
    "Roadkill",
    "NaturalCause",
    "No other death reasons",
    "Other",
    "Unknown",
    "Not applicable",
  ]

export const BaitType = [
"Whole carcass",
"Piece of meat",
"Piece of sausage",
"Liver - intestines",
"Bones",
"Mincemeat",
"Hide",
"Fat",
"Paraffin bait - cyanide",
"Fish",
"Cheese",
"Fruit",
"Water",
"Seeds",
"Other",
"Unknown",
]

export const CollectedBy = ["Authority", "NGO", "Other", "Unknown"]

export const SentTo = ["Toxicological lab", "Police CSI", "Other", "Unknown"]

export const PoisonType = ["Insecticide", "Fungidice", "Herbicide", "Not available"]

export const Toxin = [
    "Carbofuran",
    "Methomyl",
    "Potassium cyanide",
    "Methamidophos",
    "Fenthion",
    "Sulphur",
    "Methyl-Parathion",
    "Malathion",
    "Heptachlor",
    "Endosulfan",
    "Phorate",
    "Strychnine",
    "Methidathion",
    "Carbaryl",
    "Chlorpyrifos",
    "Endrin aldehyde",
    "Not available",
    "Cyproconazole",
    "Metribuzin",
]

export const ToxinGroup = [
    "Carbamate",
    "Cyanide",
    "Organophosphate",
    "Other",
    "Organochlorines",
    "Not available",
]

export const LegalStatus = ["Illegal", "Restricted use", "Legal", "Not available"]