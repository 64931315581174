<template>
  <v-card width="100%">
    <!-- <v-card-title class="" style="background-color: #7eb09f"
      >{{ $t('report.title') }}
      <v-spacer />
      <v-btn icon @click.stop="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title
    > -->

    <v-toolbar color="#7eb09f" class="mb-2">
      <v-spacer />
      <v-toolbar-title>   
        {{ $t('report.title') }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click.stop="close"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <!-- main placeholders -->
        <v-card-text>
          {{ $t("report.intro") }}
        </v-card-text>

      <v-row dense>
        <v-col>
        <v-card>
          <v-card-title>{{ $t('report.contacttitle')}}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="xs-2 shrink">
                <v-avatar
                  tile
                  color="biscuit"
                >
                  <v-icon x-large>mdi-account</v-icon>
                </v-avatar>
              </v-col>
              <v-col>
                <v-text-field
                    :label="$t('report.namesurname')"
                    v-model="name_surname"
                    required
                    dense
                    :disabled="anonymous"
                  />
                  <v-text-field
                    :label="$t('report.agencyOrganization')"
                    v-model="agencyOrganization"
                    dense
                    :disabled="anonymous"
                  />
                  <v-text-field
                    :label="$t('report.town')"
                    v-model="town"
                    dense
                    :disabled="anonymous"
                  />
                  <v-text-field
                    :label="$t('report.email')"
                    v-model="email"
                    dense
                    :disabled="anonymous"
                  />
                  <v-text-field
                    :label="$t('report.phone')"
                    v-model="phone"
                    required
                    dense
                    :disabled="anonymous"
                  />
                  <v-checkbox
                    :label="$t('report.anonymous')"
                    v-model="anonymous"
                  />
              </v-col>
            </v-row>
            <v-textarea
              filled
              :placeholder="$t('report.learnOfEvent')"
              />
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title>{{ $t('report.incidentinfo' )}}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="xs-2 shrink">
                <v-avatar
                  tile
                  color="biscuit"
                >
                  <v-icon x-large>mdi-lightning-bolt</v-icon>
                </v-avatar>
              </v-col>
              <v-col>
                <v-menu
                  v-model="dateOfEventMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dateOfEventDisplay"
                      :label="$t('report.dateOfEvent')"
                      readonly
                      v-on="on"
                      append-icon="mdi-calendar-month"
                      dense
                      outlined
                    />
                  </template>
                  <v-date-picker
                    v-model="dateOfEvent"
                    :locale="locale"
                    color="#7eb09f"
                    min="1980-01-01"
                    max="2050-01-01"
                    @change="setDateOfEvent"
                    @input="dateOfEventMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-select
              :label="$t('report.deadAnimalInvolved')"
              v-model="deadAnimalInvolved"
              :items="choices"
            />
            <v-textarea
              :label="$t('report.noAnimalAndState')"
              v-model="noAnimalAndState"
              filled
            />
            <v-select
              :label="$t('report.baitFound')"
              v-model="baitFound"
              :items="choices"
            />
            <v-textarea
              :label="$t('report.noBaitsAndType')"
              v-model="noBaitsAndType"
              filled
            />
          </v-card-text>
        </v-card>


        <v-card>
          <v-card-title>{{ $t('report.eventLocation') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="xs-2 shrink">
                <v-avatar
                  tile
                  color="biscuit"
                >
                  <v-icon x-large>mdi-map-marker</v-icon>
                </v-avatar>
              </v-col>
              <v-col>
                <!-- <v-text-field
                  :label="$t('report.country')"
                  v-model="country"
                  required
                  dense
                /> -->
                <v-select
                  :label="$t('report.country')"
                  v-model="country"
                  :items="Country"
                  required
                  dense
                />
                <v-text-field
                  :label="$t('report.region')"
                  v-model="region"
                  required
                  dense
                />
                <v-text-field
                  :label="$t('report.county')"
                  v-model="county"
                  dense
                />
                <v-text-field
                  :label="$t('report.municipality')"
                  v-model="municipality"
                  dense
                />
                <v-row>
                  <v-col>
                    <v-text-field 
                      :label="$t('report.lat')" 
                      v-model.number="lat" 
                      type="number"
                      outlined 
                      dense
                    />
                  </v-col>
                  <v-col>
                    <v-text-field 
                      :label="$t('report.lng')" 
                      v-model.number="lng"
                      type="number"
                      outlined 
                      dense 
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-card-text>
              {{$t('report.mapexplanation')}}
            </v-card-text>
            <div style="height: 500px; width: 100%">
              <l-map
                ref="reportMap"
                v-if="showMap"
                :zoom="zoom"
                :center="center"
                :options="mapOptions"
                style="height: 100%"
                @update:center="centerUpdate"
                @update:zoom="zoomUpdate"
                @click="addMarker"
              >
                <l-control-zoom position="bottomright"></l-control-zoom>
                <l-tile-layer :url="url" :attribution="attribution" />
                <l-marker v-for="marker, index in markers"
                  :lat-lng.sync="marker.position"
                  :draggable="marker.draggable"
                  @click="removeMarker(index)"
                  @update:lat-lng="setMarkerCoordinates"
                  :key="index"
                ></l-marker>
              </l-map>
            </div>
          </v-card-text>
        </v-card>

        </v-col>
      </v-row>

      <v-checkbox
        :label="$t('report.terms')"
        v-model="terms"
        required
      />
      <span
        @click.prevent="termsdialog=true" 
        class="blue--text" 
        style="cursor:pointer;">
      {{ $t('report.readTerms') }}
      </span>
        
        <!-- add the captcha -->
      <v-dialog
        v-model="termsdialog"
        width="400"
      >
        <v-card>
          <v-card-title>{{ $t('report.termsandconditions') }}</v-card-title>
          <v-card-text>
            <p>ADD ME</p>
          </v-card-text>
        </v-card>
      </v-dialog>
      

      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn 
        :disabled="!terms"
        color="guacamole darken-1"  
        @click="submit"  
        class="mb-6" 
        width="30%">
        <v-progress-circular indeterminate v-if="loading" />
        <span v-else>
        {{ $t("report.submit") }}
        </span>
      </v-btn>
      <v-spacer/>
      <!-- <my-captcha :callSuccess="captchaBtn"/> -->
      <!-- <v-btn class="button" :disabled="btndis" :click="clicked">Submit</v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { get } from 'vuex-pathify'
// import { mapActions } from "vuex";
import { latLng } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LControlZoom,
} from "vue2-leaflet";
import { formatDate } from '@/utils/general.js'
import { Country } from '@/utils/selectoptions.js'
// import myCaptcha from 'vue-captcha'

export default {
  name: "Report",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    // 'my-captcha': myCaptcha
  },
  data: () => ({
    name_surname: "",
    agencyOrganization: "",
    town: "",
    phone: "",
    email: "",
    learnOfEvent: "",
    dateOfEvent: "",
    dateOfEventDisplay: "",
    country: "",
    region: "",
    county: "",
    municipality: "",
    lat: "",
    lng: "",
    deadAnimalInvolved: "",
    noAnimalAndState: "",
    baitFound: "",
    noBaitsAndType: "",
    terms: false,
    choices: ["Yes", "No", "I don't know"],
    anonymous: false,
    dateOfEventMenu: false,
    Country: Country,
    termsdialog: false,
    zoom: 8.5,
    center: latLng(38.682613, 22.660929),
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    showMap: true,
    currentZoom: 11.5,
    currentCenter: latLng(47.41322, -1.219482),
    mapOptions: {
      zoomSnap: 0.5,
      zoomControl: false,
    },
    markers: []
  }),
  methods: {
    // ...mapActions({
    //   createReport: "reports/createReport",
    // }),
    async submit() {
      const payload = {
        name_surname: this.name_surname,
        agency_organization: this.agencyOrganization,
        town: this.town,
        phone: this.phone,
        email: this.email,
        learnOfEvent: this.learnOfEvent,
        dateOfEvent: this.dateOfEvent,
        country: this.country,
        region: this.region,
        county: this.county,
        municipality: this.municipality,
        lat: parseFloat(this.lat),
        lng: parseFloat(this.lng),
        deadAnimalInvolved: this.deadAnimalInvolved,
        noAnimalAndState: this.noAnimalAndState,
        baitFound: this.baitFound,
        noBaitsAndType: this.noBaitsAndType,
        terms: this.terms,
      };
      // await this.createReport(payload);
      await this.$store.dispatch('report/postReport', payload)
      this.$emit("report-complete", "this is the success message");
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    addMarker(evt) {
      const newMarker = {
        position: { lat: evt.latlng.lat, lng: evt.latlng.lng },
        draggable: true,
        visible: true
      }
      if (this.markers.length > 0 ) {
        return
      }
      this.lat = evt.latlng.lat
      this.lng = evt.latlng.lng
      this.markers.push(newMarker)
    },
    removeMarker(index) {
      this.markers.splice(index, 1)
      this.lat = ''
      this.lng = ''
    },
    setMarkerCoordinates(evt) {
      this.lat = evt.lat
      this.lng = evt.lng
    },
    close () {
      this.$emit("closedialog", true)
    },
    setDateOfEvent (evt) {
      this.dateOfEventDisplay = formatDate(evt)
    },
    // test captcha
    // captchaOk () {
    //   console.log('captcha ok.!')
    // },
    // captchaBtn () {
    //  this.btndis = false
    // },
    // clicked () {
    //   alert('button active.!')
    // }
  },
  computed: {
    ...get('report',[
      'loading'
    ]),
    locale () {
      return this.$i18n.locale
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.reportMap.mapObject.invalidateSize()
    })
  }
};
</script>
